import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/about.vue')
  },
  {
    path: '/achievement',
    name: 'Achievement',
    component: () => import('../views/achievement.vue')
  },
  {
    path: '/ranking',
    name: 'Ranking',
    component: () => import('../views/ranking.vue')
  },
  {
    path: '/indexs',
    name: 'Indexs',
    component: () => import('../views/indexs.vue')
  },
  {
    path: '/report',
    name: 'Report',
    component: () => import('../views/report.vue')
  }
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  base: '/',
  routes
})

export default router
