<template>
  <div class="body-content">
    <div class="body-table">
      <div class="table-css">
        <slot name="table"/>
        <div v-if="pageData" class="pager" :style="showSizes ? {'padding': '0 20px 20px'} : ''">
          <el-row style="line-height: 40px;">
            <el-col :span="10">
              <span class="pager-current">共 {{ total }} 条记录 第 {{ current }}/{{ totalPage }} 页</span>
            </el-col>
            <el-col :span="12" :offset="2">
              <el-pagination
                v-if="showSizes"
                :page-count="totalPage"
                :current-page.sync="current"
                :page-sizes="[10, 20, 50, 100, 200]"
                :page-size="pageObject.size"
                class="pagination"
                background
                layout="prev, pager, next, sizes, jumper"
                @size-change="handleSizeChange"
                @current-change="pageSearch"/>
              <el-pagination
                v-if="!showSizes"
                :page-count="totalPage"
                :current-page.sync="current"
                :page-size="5"
                class="pagination"
                background
                layout="prev, pager, next, jumper"
                @current-change="pageSearch"/>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BodyContent',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    pageData: {
      type: Object
    },
    showSizes: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      current: 0,
      size: 0,
      totalPage: 0,
      total: 0,
      pageObject: {
        page: 1,
        size: 10
      },
    }
  },
  watch: {
    'pageData': function(val) {
      if (val === undefined) {
        return
      }
      const { current, size, totalPage, total } = val
      this.size = size
      this.current = current
      this.pageObject.size = size ? this.size : 10
      this.pageObject.page = current ? this.current : 1
      this.totalPage = totalPage
      this.total = total
    }
  },
  methods: {
    pageSearch(val) {
      this.pageObject.page = val
      this.$emit('initData', this.pageObject)
    },
    handleSizeChange(val) {
      this.pageObject.size = val
      this.$emit('initData', this.pageObject)
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .body-table {
    height: 100%;
    background-color: #fff;
    overflow-x: hidden;

  .pager {
    background-color: #fff;

  .pager-current {
    font-size: 14px;
    color: #606266;
  }

  .pagination {
    margin-top: 6px;
    float: right;
  }

  }
  }

  .table-css {
    width: 100%;
    background-color: #fff;
  }
</style>
