<template>
  <div class="container" element-loading-text="Loading" v-loading="listLoading">
    <BodyContent :pageData="pageData" @initData="initData">
      <div slot="table">
        <div class="table-body-content" style="padding: 20px 20px 10px 20px">
          <div class="table-header">
            <div class="search-box">
              <div class="search-title">方案状态</div>
              <el-select
                  v-model="requestParams.state"
                  style="width: 250px; margin-right: 20px"
                  clearable
                  placeholder="请选择"
              >
                <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.orgName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </div>

            <div class="search-box">
              <div class="search-title">考核期</div>
              <el-date-picker
                  v-model="requestParams.time"
                  type="date"
                  placeholder="选择日期">
              </el-date-picker>
            </div>

            <div class="search-box">
              <div class="search-title">方案负责人</div>
              <el-select
                  v-model="requestParams.state"
                  style="width: 250px; margin-right: 20px"
                  clearable
                  placeholder="请选择"
              >
                <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.orgName"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="search-box">
              <div class="search-title">关键字</div>
              <el-input
                  placeholder="请输入考核组名称、描述进行查询"
                  clearable
                  style="width: 250px; margin-right: 20px"
                  v-model.trim="requestParams.keyword"
                  maxlength="100"
              />
            </div>
            <el-button type="text">重置</el-button>
          </div>


          <div class="table-content">
            <el-button type="text" icon="el-icon-plus" @click="dialogFormVisible = true">新增</el-button>
            <el-button type="text" icon="el-icon-delete">删除</el-button>
            <el-button type="text" icon="el-icon-s-promotion">提交考核</el-button>
            <el-button type="text" icon="el-icon-back">撤回考核</el-button>
            <el-button type="text" icon="el-icon-s-finance">评估确定</el-button>
            <el-table :data="tableData" stripe border ref="bannerTable" row-key="id" fit>
              <el-table-column align="center" type="selection" width="80"></el-table-column>
              <el-table-column align="center" label="方案名称">
                <template slot-scope="scope">{{scope.row.programmeName}}</template>
              </el-table-column>
              <el-table-column align="center" label="考核期">
                <template slot-scope="scope">{{scope.row.assessCycle}}</template>
              </el-table-column>
              <el-table-column align="center" label="考核起止日期">
                <template slot-scope="scope">{{scope.row.beginDate}} ~ {{scope.row.endDate}}</template>
              </el-table-column>
              <el-table-column align="center" label="方案负责人">
                <template slot-scope="scope">{{scope.row.dutyName}}</template>
              </el-table-column>
              <el-table-column align="center" label="考核部门数">
                <template slot-scope="scope">{{scope.row.depSize}}</template>
              </el-table-column>
              <el-table-column align="center" label="方案状态">
                <template slot-scope="scope">{{scope.row.programmeState}}</template>
              </el-table-column>
              <el-table-column align="center" label="考核进度">
                <template slot-scope="scope">{{scope.row.programmeProgress}}</template>
              </el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <el-button type="text" icon="el-icon-edit-outline" @click="dialogFormVisible = true"></el-button>
                  <el-button type="text" icon="el-icon-s-promotion"
                             @click="showRemoveHandler(scope.row.id)"></el-button>
                  <el-button type="text" icon="el-icon-delete" @click="showRemoveHandler(scope.row.id)"></el-button>
                  <el-button type="text" icon="el-icon-back" @click=" assessment = true "></el-button>
                  <el-button type="text" icon="el-icon-s-finance" @click="assessment = true"></el-button>
                  <el-button type="text" icon="el-icon-s-management" @click=" assessment = true"></el-button>
                  <el-button type="text" icon="el-icon-s-fold" @click="showRemoveHandler(scope.row.id)"></el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <el-dialog :visible.sync="assessment">
          <div slot="title" class="dialog_title">
            考核详情
            <el-button icon="el-icon-rank" type="text"></el-button>
          </div>
          <el-descriptions>
            <el-descriptions-item label="方案名称：">2021年12月业务部门绩效考核</el-descriptions-item>
            <el-descriptions-item label="考核起止日期：">2021-11-17 ~ 2021-12-31</el-descriptions-item>
          </el-descriptions>
          <el-collapse v-model="activeNames">
            <el-collapse-item title=" 节点1：部门自评（进行中）" name="1">
              <el-descriptions>
                <el-descriptions-item label="考核对象总数：">16个</el-descriptions-item>
                <el-descriptions-item label="已评估总个数：">70个</el-descriptions-item>
                <el-descriptions-item label="未评估总个数：">70个</el-descriptions-item>
              </el-descriptions>
              <el-table :data="tableData" stripe border ref="bannerTable" row-key="id" fit>
                <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
                <el-table-column align="center" label="考核对象">
                  <template slot-scope="scope">{{scope.row.name}}</template>
                </el-table-column>
                <el-table-column align="center" label="所属地区">
                  <template slot-scope="scope">{{scope.row.createTime}}</template>
                </el-table-column>
                <el-table-column align="center" label="考核报表（模板）">
                  <template slot-scope="scope">{{scope.row.num}}</template>
                </el-table-column>
                <el-table-column align="center" label="考核状态">
                  <template slot-scope="scope">{{scope.row.describe}}</template>
                </el-table-column>
                <el-table-column align="center" label="评估总分">
                  <template slot-scope="scope">{{scope.row.founder}}</template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                  <template>
                    <el-button type="text" icon="el-icon-s-grid" @click="detailed = true"></el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-collapse-item>
            <el-collapse-item title=" 节点1：部门自评（进行中）" name="2">
            </el-collapse-item>
            <el-collapse-item title=" 节点1：部门自评（进行中）" name="3">
            </el-collapse-item>
            <el-collapse-item title=" 节点1：部门自评（进行中）" name="4">
            </el-collapse-item>
          </el-collapse>
        </el-dialog>


        <el-dialog :visible.sync="detailed">
          <div slot="title" class="dialog_title">
            评估明细
            <el-button icon="el-icon-rank" type="text"></el-button>
          </div>
          <el-descriptions column="2">
            <el-descriptions-item label="考核对象">四川省/行政部</el-descriptions-item>
            <el-descriptions-item label="考核提交时间">2021-11-17</el-descriptions-item>
            <el-descriptions-item label="换算系数">无</el-descriptions-item>
            <el-descriptions-item label="评估总分">89 分</el-descriptions-item>
            <br>
            <el-descriptions-item label="评估明细" labelStyle="width:80px" :span="2" contentStyle="flex:1">
              <el-table :data="tableData" stripe border ref="bannerTable" row-key="id" fit>
                <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
                <el-table-column align="center" label="指标分类">
                  <template slot-scope="scope">{{scope.row.name}}</template>
                </el-table-column>
                <el-table-column align="center" label="指标名称">
                  <template slot-scope="scope">{{scope.row.createTime}}</template>
                </el-table-column>
                <el-table-column align="center" label="分值范围">
                  <template slot-scope="scope">{{scope.row.num}}</template>
                </el-table-column>
                <el-table-column align="center" label="指标权重（%）">
                  <template slot-scope="scope">{{scope.row.describe}}</template>
                </el-table-column>
                <el-table-column align="center" label="考评打分">
                  <template slot-scope="scope">{{scope.row.founder}}</template>
                </el-table-column>
              </el-table>
            </el-descriptions-item>
          </el-descriptions>
        </el-dialog>


        <el-dialog :visible.sync="dialogFormVisible" width="70%">
          <div slot="title" class="dialog_title">
            {{ruleForm.id ? '编辑' : '新增'}}
            <el-button icon="el-icon-rank" type="text"></el-button>
          </div>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">

            <el-tabs v-model="activeName">
              <el-tab-pane label="方案基本信息" name="first">

                <el-form-item label="方案名称：" prop="programmeName" style="width:50%;display:inline-block">
                  <el-input v-model="ruleForm.programmeName" maxlength="200"></el-input>
                </el-form-item>
                <el-form-item label="考核期：" prop="state" style="width:50%;display:inline-block">
                  <el-select v-model="ruleForm.assessCycle" placeholder="请选择" style="width:33%;margin-right: 2px;">
                    <el-option
                        v-for="item in AssessCycle"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                  <el-date-picker
                      v-model="ruleForm.assessYear"
                      type="year"
                      style="width:33%;margin-right: 2px;"
                      placeholder="选择年">
                  </el-date-picker>
                  <el-date-picker v-if="ruleForm.assessCycle == 'MONTH'"
                                  v-model="ruleForm.assessMonth"
                                  type="month"
                                  style="width:33%;"
                                  placeholder="选择月份">
                  </el-date-picker>
                  <el-select v-model="ruleForm.assessQuarter" v-if="ruleForm.assessCycle == 'QUARTER'" placeholder="选择季度"
                             style="width:33%;">
                    <el-option
                        v-for="item in AssessQuarter"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="考核起止日期：" style="width:50%;display:inline-block">
                  <el-date-picker
                      style="width:100%"
                      v-model="ruleForm.date"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="评分精度：" style="width:50%;display:inline-block">
                  <el-radio-group v-model="ruleForm.scoringAccuracy">
                    <el-radio label="INTEGER">整数</el-radio>
                    <el-radio label="ONE_DECIMAL">1位小数</el-radio>
                    <el-radio label="TWO_DECIMAL">2位小数</el-radio>
                    <el-radio label="THREE_DECIMAL">3位小数</el-radio>
                  </el-radio-group>
                </el-form-item>

                <el-form-item label="进位规则：" style="width:50%;display:inline-block">
                  <el-select style="width:100%" v-model="ruleForm.carryRule" placeholder="请选择">
                    <el-option label="四舍五入" value="ROUND_HALF_UP"></el-option>
                    <el-option label="向上进位" value="ROUND_UP"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="方案负责人：" style="width:50%;display:inline-block">
                  <el-select style="width:100%" v-model="ruleForm.dutyUserId" placeholder="请选择">
                  </el-select>
                </el-form-item>

              </el-tab-pane>
              <el-tab-pane label="考核范围配置" name="second">
                <div class="table-content">
                  <el-button type="text" icon="el-icon-plus" @click="innerVisible = true">新增部门</el-button>
                  <el-button type="text" icon="el-icon-delete">删除部门</el-button>
                  <el-table :data="ruleForm.deps" stripe border ref="bannerTable" row-key="id" fit>
                    <el-table-column align="center" type="selection" width="80"></el-table-column>
                    <el-table-column align="center" label="部门名称">
                      <template slot-scope="scope">{{scope.row.depName}}</template>
                    </el-table-column>
                    <el-table-column align="center" label="所属地区">
                      <template slot-scope="scope">{{scope.row.depAddress}}</template>
                    </el-table-column>
                    <el-table-column align="center" label="所属考核组">
                      <template slot-scope="scope">{{scope.row.depGroup}}</template>
                    </el-table-column>
                    <el-table-column align="center" label="所属考核组">
                      <template slot-scope="scope">
                        <el-select v-model="scope.row.zu" placeholder="请选择">
                          <el-option
                              v-for="item in options"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                          </el-option>
                        </el-select>
                      </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作">
                      <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-delete"
                                   @click="removeChoosedDep(scope.$index)"></el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-tab-pane>
              <el-tab-pane label="考核流程配置" name="third">
                <el-form-item label="流程类型：">
                  <el-radio-group v-model="ruleForm.flow">
                    <el-radio label="SINGLE">单一流程</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="流程节点：" prop="flowNodes">
                  <div class="table-content">
                    <el-button type="text" icon="el-icon-plus" @click="addFlowNode">新增节点</el-button>
                    <el-button type="text" icon="el-icon-delete">删除节点</el-button>
                    <el-table :data="ruleForm.flowNodes" stripe border ref="bannerTable" row-key="id" fit>
                      <el-table-column align="center" type="selection" width="80"></el-table-column>
                      <el-table-column align="center" label="节点名称">
                        <template slot-scope="scope">{{scope.row.flowName}}</template>
                      </el-table-column>
                      <el-table-column align="center" label="评估方式">
                        <template slot-scope="scope">{{scope.row.flowWay}}</template>
                      </el-table-column>
                      <el-table-column align="center" label="评估人">
                        <template slot-scope="scope">{{scope.row.flowUser}}</template>
                      </el-table-column>
                      <el-table-column align="center" label="权重（%）">
                        <template slot-scope="scope">
                          <el-input-number controls-position="right" v-model="scope.row.weight" :precision="0" :max="100" :min="0"/>
                        </template>
                      </el-table-column>
                      <el-table-column align="center" label="操作">
                        <template slot-scope="scope">
                          <el-button type="text" icon="el-icon-arrow-up"
                                     @click="showRemoveHandler(scope.$index)"></el-button>
                          <el-button type="text" icon="el-icon-arrow-down"
                                     @click="showRemoveHandler(scope.$index)"></el-button>
                          <el-button type="text" icon="el-icon-delete"
                                     @click="showRemoveHandler(scope.$index)"></el-button>

                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-form-item>

              </el-tab-pane>

            </el-tabs>
          </el-form>


          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="addProgramme('ruleForm')">确 定</el-button>
          </div>
          <el-dialog
              width="50%"
              title="选择部门"
              :visible.sync="innerVisible"
              append-to-body center>
            <div slot="title" class="dialog_title">
              选择部门
              <el-button icon="el-icon-rank" type="text"></el-button>
            </div>
            <el-row :gutter="20">
              <el-col :span="12">
                <div class="title">待选部门</div>
                <div class="left">
                  <el-input
                      placeholder="请输入部门名称进行查询"
                      clearable
                      style="width: 100%"
                      v-model.trim="requestParams.keyword"
                  />
                  <div class="list">
                    <div v-for="(item) in 7" :key="item" @click="addDep">
                      行政部（四川省）
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="title">已选部门 <span style="float:right">已选总数：{{ruleForm.deps.length}}</span></div>
                <el-table :data="ruleForm.deps" stripe border ref="bannerTable" row-key="id" fit>
                  <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                      <el-button type="text" icon="el-icon-delete" @click="removeChoosedDep(scope.$index)"></el-button>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="所属地区">
                    <template slot-scope="scope">{{scope.row.depAddress}}</template>
                  </el-table-column>
                  <el-table-column align="center" label="所属考核组">
                    <template slot-scope="scope">{{scope.row.depGroup}}</template>
                  </el-table-column>
                  <el-table-column align="center" label="部门名称">
                    <template slot-scope="scope">{{scope.row.depName}}</template>
                  </el-table-column>
                </el-table>
              </el-col>
            </el-row>

            <span slot="footer" class="dialog-footer">
              <el-button @click="innerVisible = false">取 消</el-button>
              <el-button type="primary" @click="innerVisible = false">确 定</el-button>
            </span>
          </el-dialog>
        </el-dialog>
      </div>
    </BodyContent>
  </div>
</template>

<script>
  export default {
    name: "Home",
    data() {
      return {
        activeNames: '1',
        detailed: false,
        assessment: false,
        activeName: 'first',
        innerVisible: false,
        dialogFormVisible: false,
        listLoading: false,
        stateList: [],
        requestParams: {
          time: '',
          state: "",
        },
        rules: {
          programmeName: [{required: true, message: '请填写名称'}],
          state: [{required: true, message: '请选择状态'}],
          deps: [{type: 'array', required: true, message: '请选择考核对象', trigger: 'change'}],
          flowNodes: [{type: 'array', required: true, message: '请设置流程节点', trigger: 'change'}],
        },
        ruleForm: {
          name: '',
          id: '',
          scoringAccuracy: 'INTEGER',
          carryRule: '',
          state: false,
          deps: [],
          flowNodes: [],
          flow: 'SINGLE'
        },
        choosedDeps: [],
        options: [],
        pageData: {},
        tableData: [],
        AssessCycle: [
          {label: '年度', value: 'YEAR'},
          {label: '季度', value: 'QUARTER'},
          {label: '月份', value: 'MONTH'},
        ],
        AssessQuarter: [
          {label: 'Q1季度', value: 'Q1'},
          {label: 'Q2季度', value: 'Q2'},
          {label: 'Q3季度', value: 'Q3'},
          {label: 'Q4季度', value: 'Q4'},
        ]
      };
    },
    mounted() {
      this.initData()
    },
    methods: {
      initData() {
        this.tableData = [{
          programmeName: "方案名称1",
          assessCycle: "2021-Q1",
          beginDate: "2021-01-01",
          endDate: "2021-03-31",
          dutyName: "黄蓉",
          programmeState: "草稿",
          programmeProgress: "未生效",
          depSize: 3,
        }, {
          programmeName: "方案名称2",
          assessCycle: "2021-Q2",
          beginDate: "2021-04-01",
          endDate: "2021-06-30",
          dutyName: "剑圣",
          programmeState: "草稿",
          programmeProgress: "未生效",
          depSize: 4,
        }]
      },
      addProgramme(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.dialogFormVisible = false
            this.tableData.push({
              programmeName: "方案名称1",
              assessCycle: "2021-Q1",
              beginDate: "2021-01-01",
              endDate: "2021-03-31",
              dutyName: "黄蓉",
              programmeState: "草稿",
              programmeProgress: "未生效",
              depSize: 3,
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      addFlowNode() {
        this.ruleForm.flowNodes.push({
          flowName: '部门自评' + Math.random(),
          flowWay: '部门自评',
          flowUser: '部门自评',
          weight: 0,
        })
      },
      addDep() {
        this.ruleForm.deps.push({
          depAddress: '四川省',
          depName: '人力资源部',
          depGroup: '人力资源部',
        })
      },
      removeChoosedDep(index) {
        this.ruleForm.deps.splice(index, 1)
      },
      showRemoveHandler(index) {
        this.ruleForm.flowNodes.splice(index, 1)
      }
    },
  };
</script>
<style lang="scss" scoped>
  .title {
    margin-bottom: 5px;
  }

  .left {

    border: 1px solid #DCDFE6;
    border-radius: 4px;

  /deep/ .el-input__inner {
    border: 0;
    border-bottom: 1px solid #DCDFE6;
  }

  .list {
    padding: 0 5px;

  div {
    padding: 10px;
  }

  }
  }

</style>
